import { Album, ArticleOutlined, CreateNewFolder, Delete, DocumentScannerRounded, Download, Edit, FileDownload, Folder, FolderShared, Home, LocalPostOffice, Photo, PhotoAlbum, PictureAsPdf, PictureAsPdfRounded, Save, SearchOffRounded, SearchRounded, StorageRounded, Upload } from '@mui/icons-material'
import { TextField, Typography, Box,List, Grid, Toolbar, LinearProgress, ButtonGroup, IconButton, Paper, Button, ListItem, ListItemIcon, ListItemText, ListItemButton, Collapse, CircularProgress } from '@mui/material'
import React from 'react'
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import ProgressBar from "@ramonak/react-progress-bar";

const Storage = (props) => {
    const [active,setactive] = props.states
  return (
<Box style={{display: active == 5?'block':'none', transition: 'eas-in-out 0.5s'}}>
<Toolbar sx={{borderBottom: '0px solid #4c4c4c94',
boxShadow: '1px 1px 1px 0px #d9d9d9',
width:"95%"
}}>
  <Typography variant='h5'>የኔ ፋይሎች</Typography> 
  <Album color='primary' fontSize='large' style={{    marginLeft: '38px'
}}/>
  <span style={{
    width: '60%',
  }}>
    <ProgressBar 
     completed={50}
     maxCompleted={100} 
     bgColor="#0099ff"
     height="30px"
     customLabel={<Typography>5MB/10MB ማስቀመጫ 50%</Typography>}
     />
    </span>
</Toolbar>
<Grid container sx={{margin:1}}>
<Grid item md={4}>

<Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="የፍለጋ ፋይሎች"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>

    <List>
    <ListItemButton sx={{    marginBlock: "-1px",boxShadow: '1px 1px 1px 0px #d9d9d9',marginBottom:1}}>
        <ListItemIcon>
          <FolderShared style={{fontsize:15}} color='primary' fontSize='large'  />
        </ListItemIcon>
        <ListItemText primary="userName.eplusapp.root" />
        <hr />
      </ListItemButton>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Office files" />
          </ListItemButton>





          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Account information" />
          </ListItemButton>



          


          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Musics" />
          </ListItemButton>





          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Documents" />
          </ListItemButton>





          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Photos" />
          </ListItemButton>





          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItemButton>



          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Word" />
          </ListItemButton>



          <ListItemButton  sx={{pl: 4, marginBlock: "-10px",boxShadow: '1px 1px 1px 0px #d9d9d9'}}>
            <ListItemIcon>
              <Folder style={{fontsize:15}} color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary="Powerpoint" />
          </ListItemButton>



          
        </List>
      </Collapse>
    </List>
    
</Grid>
<Grid item md={8}>
  <Toolbar style={{boxShadow: '1px 1px 1px 0px #d9d9d9'}}> 
    <ButtonGroup>
    <Button color='info' startIcon={<CreateNewFolder/>}>አዲስ ማህደር</Button>
    <Button color='info' startIcon={<FileDownload/>}>ኣውርድ</Button>
    <Button color='warning' startIcon={<Edit/>}>ኣስተካክል</Button>
    <Button color='error' startIcon={<Delete/>}>ኣጠፋ</Button>
    </ButtonGroup>
      </Toolbar>
  <List>

  <ListItemButton style={{background: "#dfdfdf"}}>
        <ListItemText primary={<Typography variant='body1'>ስም</Typography>}/>
    </ListItemButton>




    <ListItemButton>
      <ListItemIcon>
        <PictureAsPdfRounded fontSize='large'/>
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body1'>Book.com Software enginering</Typography>}/>
        <ListItemText primary={<Typography variant='body1'>1.4 MB</Typography>}/>
        <ListItemText primary={<Download/>}/>
    </ListItemButton>
    

    <ListItemButton >
      <ListItemIcon>
        <Photo fontSize='large'/>
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body1'>IMG45652376e28</Typography>}/>
        <ListItemText primary={<Typography variant='body1'>14 MB</Typography>}/>
        <ListItemText primary={<Download/>}/>
    </ListItemButton>
    
    <ListItemButton >
      <ListItemIcon>
        <DocumentScannerRounded fontSize='large'/>
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body1'>Txs2376e28.tsx</Typography>}/>
        <ListItemText primary={<Typography variant='body1'>4 MB</Typography>}/>
        <ListItemText primary={<Download/>}/>
    </ListItemButton>
    



  </List>
</Grid>
</Grid>
</Box>
  )
}

export default Storage