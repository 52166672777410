import { Alert, Button, Typography } from '@mui/material'
import Spreadsheet from "react-spreadsheet";
import { useState, useEffect } from 'react'; 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import logo from '../../assets/circle.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyown, Reset } from '../../data/Organization';
import Loading from '../../components/Loading';
import api from '../../data/api';
import SnackBar from '../../components/SnackBar';
import style from '../../styles';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { ResetHasab } from '../../data/OrgDetail';
const baseURL = api.address;
const Org0 = () => {

    const run = useDispatch();
    const MyOrg = useSelector((state)=>state.org)
    const user = useSelector((state)=>state.logedUser)
    const Orgs = MyOrg.MyOrg;
    const [load,setload] = useState(false)
    const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
    const [connect,setcon] = useState(false)


  useEffect(()=>{

            run(fetchMyown(user.user));
            run(ResetHasab);
            if(MyOrg.isloading)
            {
                setload(true)
            }
            else if(MyOrg.status == "success")
            {
                setload(false)
                console.log(Orgs)
                run(Reset());
                setcon(false)
            }
            else if(MyOrg.status == "failed")
            {
                    setload(false)
                    setsnack({Type:"warning",msg:"የኒተዎርክ ችገር!",show:true})
                    run(Reset());
                    setcon(true);

            }
            else 
            setload(false)
            
    
        
    },[])


    useEffect(()=>{
      if(MyOrg.isloading)
      {
          setload(true)
      }
      else if(MyOrg.status == "success")
      {
          setload(false)
          console.log(Orgs)
          run(Reset());
          setcon(false)
      }
      else if(MyOrg.status == "failed")
      {
              setload(false)
              setsnack({Type:"warning",msg:"የኢንተርኔት ኔተዎርክ ችገር አለ!",show:true})
              run(Reset());
              setcon(true);

      }
      else 
      setload(false)


    },[MyOrg])

    return (
    <>
    <br />
    <Typography color="primary" variant='h6'>በዕርስዎ ስም ያሉ ድርጅቶች</Typography>
    <hr />
    
    <Loading load={load} />
    <SnackBar snack={snack} />
    <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
    <Link  style={{ color: "white"}} to="../1"><Button  style={{ color: "white"}} startIcon={<Add/>} sx={{fontSize: '15px',fontWeight: '700',color: 'white'}} color='secondary'  variant='contained'>አዲስ አስገባ</Button></Link>
    <br /><br />
    {connect?<Alert severity="error">የኢንተርኒት ግንኙነት ችግር አለ! እባክዎ በድጋሚ ይሞክሩ</Alert>:<></>}

{
    
    Orgs.map((org)=><>
            <Link style={{color:'#0099ff'}} to="/main/org/view" state={{id:org.id}} >
            <ListItem style={{borderRadius:'50px'}} sx={{background:style.colors.high,cursor:"pointer",margin:1}}>
        <ListItemAvatar>
          <Avatar src={`${baseURL}/images/${org.logo}`} >
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={org.name} secondary={org.stockFounder} />
        <ListItemText primary={org.brand} secondary={org.city} />
        <ListItemText primary={org.address} secondary={org.currentStock} />
        <ListItemText primary={org.type == "sc"?"ሽርክና":org.type == "plc"?"ፒ.ኤል.ሲ":"አክሲዮን" } secondary={org.establishment} />
      </ListItem>
            </Link>
    
    </>)
}
</List>
    </>
    )
}

export default Org0


function FolderList() {
  return (<>

    <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem >
        <ListItemAvatar>
          <Avatar src={logo}>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="ስም" secondary="ኢጵላሳጵ" />
        <ListItemText primary="ብራንድ" secondary="ኢጵላሳጵ" />
        <ListItemText primary="አድራሻ" secondary="ባህርዳር" />
        <ListItemText primary="ተቀጣሪዎች" secondary="914" />
      </ListItem>
    </List>
    </> );
}
