import React, { useEffect, useState } from 'react'
import {Button, MenuItem, NativeSelect, Select} from '@mui/material'
import { ArrowForwardIosRounded, Update, Upload } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {setOrg,createOrg} from '../../data/Organization'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import {Reset} from '../../data/Organization'
import axios from 'axios'
import api from '../../data/api';
import SnackBar from '../../components/SnackBar'
const baseURL = api.address;
const Org1 = () => {
    const nav = useNavigate()
    const [load,setload] = useState(false)
    const disp = useDispatch();
    const file = useRef();
    const Organ = useSelector((state)=>state.org);
    const user = useSelector((state)=>state.logedUser)
    const org = Organ.Org;
    const regions = ["አማራ","አዲስ አበባ","አፋር","አማራ","ቤንሻንጉል-ጉማዝ","ድሬ ዳዋ","የጋምቤላ ህዝቦች","ሃራሪ ህዝብ","ኦሮሚያ","ሲዳማ","ሶማሌ"];
    const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
    const [show,setshow] = useState(camera)
    
  
    useEffect(()=>{
        
        disp(Reset())
    },[])

    useEffect(()=>{

        if(Organ.isloading)
        setload(true)
        else if(Organ.createStatus == "success")
        {
            setload(false);
            console.log("ID",Organ.resp.data?.id)
          let id = Organ.resp.data?.id;
            nav('../view',{state:{id:id}});

        }
        else if(Organ.createStatus == "failed")
        {
            setload(false);
            setsnack({Type:"warning",msg:"አስፈላጊ መረጃዎችን በትክክል ያስገቡ ፤ ከዚ በፊት ያልተጠቀሙትን የደርጅት ኢሜል ያስገቡ!",show:true})
        }
        else {
        setload(false)

    }


    },[Organ])

const Organization = useSelector((state)=>state.org)


const submit = e => {

const token = user.user;


disp(createOrg({org:org,token:token}))


}



const Update = e => {

    let value = e.target.value;
    if(value == "")
    disp(setOrg({filed:e.target.name,value:"_"}))
    else
    disp(setOrg({filed:e.target.name,value:value}))
    
}








const upload =  e => {
   
    const call = async ()=>{
    
        let formData = new FormData();
        formData.append('logo',e.target.files[0])
        setload(true);
        const response = await axios.post(`${baseURL}/addressapi/files/uploadfile`,formData).then((resp)=>{
                
                console.log(resp)

            setload(false)
            const reader = new FileReader();
            reader.readAsDataURL(file.current.files[0]);
            reader.addEventListener("load", () => {
                setshow(reader.result);
              });
            disp(setOrg({filed:'logo',value:resp.data.logo}))

        }).catch((err)=>{
            console.log(err)
            if(err)
            setsnack({Type:"warning",msg:"የኔት ዎርክ ችግር አለ፣ እባክዎ እንደገና ይሞክሩ!",show:true})
            setload(false)
    
        })
    
    return response;
    }
    
        call();
        
        
        }
    






  return (
    <Card>
        <SnackBar snack={snack} />
        <Loading load={load}/>
    <CardContent sx={{width:"100%"}}>
    <Typography variant='h6' color="primary">የድርጅትዎ መረጃ በጥንቃቄ ይሙሉ!</Typography>
       
    <Grid container >
        <Grid item   xs={12} sm={12} md={2} lg={2} xl={2} sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
        
        <Avatar  alt='Logo' src={show}  sx={{width: '5rem',height: '5rem',margin: 'auto',marginBottom: '0',marginTop: '0px'}} />
        &nbsp;&nbsp;
        <Button onClick={e=>file.current.click()} startIcon={<Upload/>} variant='outlined'>ሎጎ አስገባ</Button>
        <input style={{display:"none"}} onChange={e=>upload(e)} ref={file} type="file" />
        <input type="text" name='logo' style={{display:"none"}} />
        </Grid>
        <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:2}}>
        <br /> 
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የድርጅት ስም</InputLabel>
            <Input name='name' onChange={e=>Update(e)} value={org.name} id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ብራንድ</InputLabel>
            <Input name='brand' onChange={e=>Update(e)} value={org.brand} id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የግብር ቁጥር</InputLabel>
            <Input value={org.tinNumber} name='tinNumber' type='number' onChange={e=>Update(e)} id="component-simple"  />
        </FormControl> 
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">አድራሻ</InputLabel>
            <Input name='address' value={org.address} onChange={e=>Update(e)} id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ሀገር</InputLabel>
            <Input onChange={e=>Update(e)} value={org.country} name='country' id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%",margin:5}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">ክልል</InputLabel>
        <Select
        onChange={e=>Update(e)}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          label="ክልል"
          value={org.region}
          name='region'
        >
          {
            regions.map((region)=>
              <MenuItem key={Math.random()} value={region}>{region}</MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ዞን</InputLabel>
            <Input name='zone' onChange={e=>Update(e)} value={org.zone} id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ወረዳ</InputLabel>
            <Input name='woreda' onChange={e=>Update(e)} value={org.woreda} id="component-simple"  />
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከተማ</InputLabel>
            <Input name='city' onChange={e=>Update(e)} value={org.city} id="component-simple"  />
        </FormControl>


    </Grid>
    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:2}}>
        <br /> 
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ክፍለ ከተማ</InputLabel>
            <Input onChange={e=>Update(e)} name='subCity' value={org.subCity} id="component-simple"  />
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ቀበሌ</InputLabel>
            <Input onChange={e=>Update(e)} value={org.kebele} name='kebele' id="component-simple"  />
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የመንገድ ቁጥር</InputLabel>
            <Input onChange={e=>Update(e)} value={org.streetNumber} name='streetNumber' id="component-simple"  />
        </FormControl>
        
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የአክሲዮን መስራቾች ብዛት</InputLabel>
            <Input onChange={e=>Update(e)} value={org.stockFounder} name='stockFounder' id="component-simple"  />
        </FormControl>


        <FormControl style={{width:"100%",margin:5}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">የስራ አይነት</InputLabel>
        <Select
            onChange={e=>Update(e)}
            value={org.type}
            name='type'
            id="demo-simple-select-standard"
        >
          <MenuItem key={Math.random()} value=''></MenuItem>
          <MenuItem key={Math.random()} value='plc'>ፒ.ኤል.ሲ</MenuItem>
          <MenuItem key={Math.random()} value='sc'>ስቶክ</MenuItem>
          <MenuItem key={Math.random()} value='partners'>ሽርክና</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የስራ መስክ</InputLabel>
            <Input onChange={e=>Update(e)} value={org.businessSector} name='businessSector' id="component-simple"  />
        </FormControl> 
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ባንክ</InputLabel>
            <Input name='bank' onChange={e=>Update(e)} value={org.bank} id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የአክሲዮን ብዛት</InputLabel>
            <Input name='numberOfStocks' type='number' onChange={e=>Update(e)} value={org.numberOfStocks} id="component-simple"  />
        </FormControl> 
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">አሁን ያለው የአክሲዮን ብዛት</InputLabel>
            <Input name='currentStock' type='number' onChange={e=>Update(e)} value={org.currentStock} id="component-simple"  />
        </FormControl> 

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
        
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ኢሜል</InputLabel>
            <Input variant="outlined" name='email' id="component-simple" onChange={e=>Update(e)} value={org.email} />
        </FormControl> 

    <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የተቋቋመበት ጊዜ</InputLabel>
            <Input name='establishment' id="component-simple" type='date' onChange={e=>Update(e)} value={org.establishment}/>
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ካፒታል</InputLabel>
            <Input name='capital' type='number' id="component-simple" onChange={e=>Update(e)}  value={org.capital}/>
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">አሁን ያለው ካፒታል</InputLabel>
            <Input name='currentCapital' type='number' id="component-simple" onChange={e=>Update(e)}  value={org.currentCapital}/>
        </FormControl>
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">የተቀጣሪዎች ብዛት</InputLabel>
            <Input name='employees' type='number' id="component-simple" onChange={e=>Update(e)} value={org.employees}/>
        </FormControl>
        <br /> 
        
    </Grid>
    
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>
       <Link to="#"><Button size='large' endIcon={<ArrowForwardIosRounded/> } onClick={submit} variant="contained">አስገባ እና ቀጥል</Button></Link> 
    </CardActions>
    <br /><br />
</Card>

    )
}

export default Org1