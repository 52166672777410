import { AdminPanelSettings, AudioFileSharp, Cancel, DateRange, Delete, Edit, Label, Photo, Send, TypeSpecimen, Update, Upload, VideoCall, VideoCallRounded } from '@mui/icons-material'
import { Grid,Box, Container, Typography, TextField, Select, MenuItem, FormControlLabel, Button, List, ListItem, Card, CardHeader, Avatar, CardContent, CardActionArea, CardActions, ButtonGroup, Dialog, DialogTitle, DialogContent, Alert, DialogActions } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import api from '../data/api'
import { useSelector } from 'react-redux'
import SnackBar from '../components/SnackBar'
import Loading from '../components/Loading'

const Promromtion = (props) => {

    const file1 = useRef()
    const file2 = useRef()
    const file3 = useRef()
    const [active,setactive] = props.states
    const userLogin = useSelector(state=>state.logedUser)
    const [orgList,setOrgsList] = useState([])
    const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
    const [myposts,setPosts] = useState([])
    const [updateState,setUpdateState] = useState(false)
    const [load,setload] = useState(false)
    const [DeletePost,setDeletePost] = useState({show:false})
    const [formData,setFormData] = useState({
        title:'',
        type:'',
        address:'',
        description:'',
        openingDate:'',
        closingDate:'',
        image:'',
        userId:'',
        orgId:''
    });
useEffect(()=>{

    (async ()=>{
        
      await axios.get(`${api.address}/addressapi/organizations/fetchownorgs/`,{
        headers: {
            'Authorization': `Bearer ${userLogin.user}`
          }   
    }).then(async (resp)=>{
      
      setOrgsList(resp.data)
      

      await axios.get(`${api.address}/addressapi/vacancies/fetchowndata`,{
        headers: {
            'Authorization': `Bearer ${userLogin.user}`
          }   
    })
    .then((result) => {
        setPosts(result.data)
    }).catch((err) => {
        console.log(err)        
    });




    })
    .catch((er)=>{
      console.log(er)
    })



    })()

},[])
    

useEffect(()=>{

    console.log(formData)

},[formData])

const post = async ()=>{

    console.log(formData)

    formData.userId = userLogin.id
    if(formData.title == '')
    setsnack({Type:'error',msg:'ርዕስ ማስገባት አለበዎት!',show:true})
    else if(formData.address == '')
    setsnack({Type:'error',msg:'አድራሻ አላስገቡም!',show:true})
    else if(formData.description == '')
    setsnack({Type:'error',msg:'ገለፃ አላስገቡም!', show:true})
    else if(formData.closingDate == '')
    setsnack({Type:'error',msg:"የመክፈቻ ቀን እና መዝግያ ቀን አስፈላጊ ናቸው!",show:true})
    else 
    {
        setload(true)
     

        if(formData.type == 'job_vacancy')
        delete formData['openingDate'] 

        await axios.post(`${api.address}/addressapi/vacancies/create`,formData,{
            headers: {
                'Authorization': `Bearer ${userLogin.user}`
              }       
        })
        .then(async (result) => {
            setload(false)
            console.log(result)
            setsnack({Type:'success',msg:result.data.message,show:true})
            setFormData({        title:'',
            type:'',
            address:'',
            description:'',
            openingDate:'',
            closingDate:'',
            image:'',
            userId:'',
            orgId:''
    })
    await axios.get(`${api.address}/addressapi/vacancies/fetchowndata`,{
        headers: {
            'Authorization': `Bearer ${userLogin.user}`
          }   
    })
    .then((result) => {
        setPosts(result.data)
    }).catch((err1) => {
        console.log(err1)        
    });

        }).catch((err) => {
            console.log(err)
            setload(false)

            setsnack({Type:'error',msg:err.response.data.message,show:true})
            
        });
    }

    



}

  return (
<Box style={{display: active == 4?'block':'none', transition: 'eas-in-out 0.5s'}}>
<SnackBar  snack={snack} />
<Loading load={load}/>

<Dialog open={DeletePost.show}>
<DialogTitle>
    <Typography color='error'>ለማጥፋት እርግጠኛ ነዎት?</Typography>
</DialogTitle>
<DialogContent>
<Alert severity='error'>
<Typography color='error'>ማስታወቅያው ከመረጃ ቋት እና ከማስታዎቅያ ገፀ ላይ ሙሉ ለሙሉ የጠፋል!</Typography>    
</Alert>
</DialogContent>
<DialogActions>
    <ButtonGroup variant='contained' >
    <Button onClick={async e=>{
        setload(true)
        await axios.delete(`${api.address}/addressapi/vacancies/${DeletePost.id}`)
        .then(async (result) => {
            setload(false)
            await axios.get(`${api.address}/addressapi/vacancies/fetchowndata`,{
                headers: {
                    'Authorization': `Bearer ${userLogin.user}`
                  }   
            })
            .then((result) => {
                setPosts(result.data)
            }).catch((err1) => {
                console.log(err1)        
            });
        
        }).catch((err) => {
            setload(false)
            setsnack({Type:'error',msg:err.response.data.message,show:true})
        });

    }} color='error'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;አወ</Button>
    <Button color='success' onClick={e=>{
        setDeletePost({show:false})
    }}>አደለሁም</Button>
    </ButtonGroup>
</DialogActions>
</Dialog>

<div id='top'></div>
<Typography sx={{padding:3}} color='GrayText' variant='h5'>የማስታወቅያ ይዘትዎች ማስተዳደርያ እና መስርያ ገፅ</Typography>
<Container>
    <Grid container>
    <Grid item xl={8} lg={8} md={8} sm={12} xs={12} style={{borderRight:'solid 1px gray'}}>

    {updateState?<Button startIcon={<Cancel/>} variant='contained' onClick={e=>{setUpdateState(false); setFormData({ type:'',
            address:'',
            description:'',
            openingDate:'',
            closingDate:'',
            image:'',
            userId:'',
            orgId:''})}} color='warning' size='small'>ማደሱን አቋርጥ</Button>:""}

        <Container>
            <Typography>አስፈላጊ መረጃዎች</Typography>
            <TextField
            value={formData.title}
            onChange={e=>setFormData({...formData,title:e.target.value})}
            variant='filled'
            placeholder='ዕርስ'
            label='ዕርስ'
                        sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}

            />&nbsp;
            <TextField 
            label='አድራሻ'
            variant='filled'
            value={formData.address}
            onChange={e=>setFormData({...formData,address:e.target.value})}

                        sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}


            />
            <br />
            <br />
            <FormControlLabel  control={<TypeSpecimen/>} label={<Typography variant='body1'>የማስታዎቅያውን አይነት ይምረጡ</Typography>} />
            <br/> <br/> 
            <Select
            variant='filled'
            sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}
            value={formData.type}
            onChange={e=>setFormData({...formData,type:e.target.value})}
            >
           <MenuItem value=''></MenuItem>
           <MenuItem value='job_vacancy'>የስራ ማስታዎቅያ</MenuItem>
           <MenuItem value='bidding'>የጨረታ ማስታዎቅያ</MenuItem>
           <MenuItem value='daily_work'>የቀነ ሰ</MenuItem>
            </Select>
            <br /><br />
            <TextField
            aria-expanded 
            fullWidth 
            multiline
            variant='filled'
            label='ሙሉ ገለፃ'
            value={formData.description}
            onChange={e=>setFormData({...formData,description:e.target.value})}
                        sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}


            />
            <br /><br />
 { formData.type ==  'job_vacancy'?'':<>       <FormControlLabel  control={<DateRange/>} label={<Typography variant='body1'>የመከፍቻ ቀን</Typography>} />
        <br/> 
       <TextField type='date'
            value={formData.openingDate}
            onChange={e=>setFormData({...formData,openingDate:e.target.value})}
       
                   sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}

        variant='filled'
        /> </>}
       <br /><br />
            <FormControlLabel  control={<DateRange/>} label={<Typography variant='body1'>የሚዘጋበት ቀን</Typography>} />
        <br/> 
       <TextField type='date' sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}

       variant='filled'
       value={formData.closingDate}
       onChange={e=>setFormData({...formData,closingDate:e.target.value})}
  
            />
       <br /><br />
       <FormControlLabel  control={<Photo/>} label={<Typography variant='body1'>ምስል {formData.image}</Typography>} /><br/>
       <Button color='success' onClick={e=>file1.current.click()} startIcon={<Upload/>} variant='contained'>ምስል ለማስገባት</Button>
       <input ref={file1} type="file" name="" style={{display:'none'}} id="" onChange={async (e)=>{

let file = e.target.files[0];
let fd = new FormData();
fd.append('logo',file)
setFormData({...formData,image:"Loading......"})

await axios.post(`${api.address}/addressapi/files/uploadfile`,fd)
.then((result) => {
        setFormData({...formData,image:result.data.logo})

}).catch((err) => {
    alert(err.response)
});
       }} />
       <Box component='img' style={{}} src={`${api.imgAddress}${formData?.image}`} />
       <br /><br />
       <FormControlLabel  control={<VideoCallRounded/>} label={<Typography variant='body1'> ተንቀሳቃሽ ምስል {formData?.video}</Typography>} /><br/>
       <Button color='success' onClick={e=>file2.current.click()} startIcon={<Upload/>} variant='contained'>ተንቀሳቃሽ ምስል ለማስገባት</Button>
        
        <input type="file" ref={file2} onChange={ async e=> {
          let formData1 = new FormData();
          formData1.append('video',e.target.files[0])
          setload(true)
          await axios.post(`${api.address}/addressapi/files/uploadfile`,formData1)
          .then((result) => {
            setload(false)
            setFormData({...formData,...result.data})
          }).catch((err) => {
            setload(false)
            console.log(err)
            setsnack({Type:"error",msg:err.response.data.message})

          });


        }} style={{display:'none'}} />



       <br /><br />
       <FormControlLabel  control={<AudioFileSharp/>} label={<Typography variant='body1'> ድምፅ {formData?.audio}</Typography>} /><br/>
       <Button color='success' onClick={e=>file3.current.click()} startIcon={<Upload/>} variant='contained'>ድምፅ ለማስገባት</Button>
        
       <input type="file" ref={file3} onChange={async e=>{


let formData1 = new FormData();
formData1.append('audio',e.target.files[0])
setload(true)
await axios.post(`${api.address}/addressapi/files/uploadfile`,formData1)
.then((result) => {
  setload(false)

  setFormData({...formData,...result.data})


}).catch((err) => {
  setload(false)
  console.log(err)
  setsnack({Type:"error",msg:err.response.data.message})

});




       }} style={{display:'none'}} />





       <br /><br />
    
       <FormControlLabel  control={<AdminPanelSettings/>} label={<Typography variant='body1'>የማስታወቅያው ባለቤት</Typography>} /><br/><br/>
       <Select 
       variant='filled'
                   sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}}

       value={formData.orgId}
       onChange={e=>setFormData({...formData,orgId:e.target.value})}
       >
{
orgList.map((org)=>(
<MenuItem value={org.id}>{org.name}</MenuItem>
))

}
       </Select>
       <br /><br /><br /><br />
       {!updateState?<Button onClick={post} startIcon={<Send/>} variant='contained'>ላክ</Button>:
       <ButtonGroup variant='contained'>
       <Button color='success' onClick={async e =>{
        
        let updates = formData;
        let id = formData.id;
        delete updates.id;
        delete updates.userId;
        delete updates.orgId;



        await axios.put(`${api.address}/addressapi/vacancies/${id}`,updates,{headers: {
            'Authorization': `Bearer ${userLogin.user}`
          }})
        .then((result) => {
            setload(false)
            setUpdateState(false)
            setFormData({   address:'',
            description:'',
            openingDate:'',
            closingDate:'',
            image:'',
            userId:'',
            orgId:''})

            setsnack({Type:'success',msg:"ማስታወቅያው ተረመለ!",show:true})
       console.log(result)
        }).catch((err) => {
            setload(false)
            setsnack({Type:'error',msg:err.response?.data.message,show:true})
            //console.log(userLogin.user,formData)
        });




       }} startIcon={<Update/>} >አድስ</Button>
       </ButtonGroup>}
       <br /><br /><br /><br />
       <hr />
        </Container>
            </Grid> 
    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} style={{paddingLeft:10}}>
        <Typography color='GrayText'><AdminPanelSettings/> ከዚ በፊተ የገቡ ማስታዎቅያዎች</Typography>
        <hr />
        <List>

{

    myposts.map((post)=>(
    <ListItem key={post.id}>
                <Card elevation={6}  draggable style={{width:'100%'}}>
                    <CardHeader style={{backgroundColor:'#f0f0f0'}} title={<Typography variant='h6'>{post.title}</Typography>} />
                    <CardContent>
                    <Typography style={{textAlign:'center'}}>{post.description}</Typography>
                    </CardContent>
                    <CardActions style={{backgroundColor:'#f0f0f0'}} >
                  <ButtonGroup variant='contained' style={{width:'100%'}}>
                        <Button  onClick={e=>{ setUpdateState(true); setFormData(post); window.location = "#top"; }} startIcon={<Edit/>} color='primary'><Typography>ማደስ</Typography></Button>
                        <Button startIcon={<Delete/>} onClick={e=>setDeletePost({show:true,id:post.id})} color='error'><Typography>ማጥፋት</Typography></Button>
                    </ButtonGroup>
                    </CardActions>
                </Card>
            </ListItem>
        
))
}

        </List>
            </Grid> 
    </Grid>
    </Container>
</Box>

    )
}

export default Promromtion