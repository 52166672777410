import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomeLoading from "../components/HomeLoading";
import ModalC from '../components/ModalComp';
import gif from "../assets/rocket_launch.gif";
import UserMainAppBar from './UserMainAppBar';
import { Box,Grid, Divider, Paper, Stack, Typography, Container } from '@mui/material';
import style from '../styles';
import logo from "../assets/circle.png"
import sbg from "../assets/wp4756884-smart-city-wallpapers.jpg"
import SideMenues from './SideMenues';
import Profile from './Profile';
import Break_point from "../breakpoint";
import  ModalYN from '../components/ModalYN'
import Org from './Org';
import Producs from './Producs';
import {fetchUserInfo} from '../data/userInfo'
import { useSelector, useDispatch } from 'react-redux';
import Lock from '../Lock';
import Promromtion from './Promromtion';
import Storage from './Storage';
 const Home = (props) => {
      const run = useDispatch()
      const userinfo = useSelector((state)=>state.userInfo)
      const user = useSelector((state)=>state.logedUser)
      const [Mn,setMn] = useState(false)
      const [active,setactive] = React.useState(1);
      const [progShow,setProgShow] = useState(true)
      const location = useLocation();
      const fromP = location.state;
      const [modal,setmodal] = useState({title:"Title",body:"Body",show:false,btn:"Button",loc:""});
      const [modalYN,setYN] = useState({value:0,show:false})
      useEffect(()=>{

        run(fetchUserInfo(user))


        if( fromP !== null && fromP.fromP == "fromUserInfo")
        {
          setmodal({title:"ኢጵላሣጵ የአድራሻ ማልማት ሂደትዎ በትክክል ተከናውኗል!!!",img:gif,body:"በቀጣይ ተጨማሪ መረጃዎችን እሚያስገቡበትን እና ያስገቡትን መረጃ ወደ እሚያስተዳድሩበት ገፀ ይሄዳሉ!",show:true,btn:"ቀጣይ",loc:"#main"})
          setProgShow(false)
        }
        else
        {
          setTimeout(() => {setProgShow(false)}, 1000);
        }
        
      },[])

      useEffect(()=>{
        if(userinfo.isloading)
        setProgShow(true)
        else
        setProgShow(false)
        
      },[userinfo])

      useEffect(()=>{

        setProgShow(true)
        setTimeout(()=>setProgShow(false),1000)
        if(active == 2)
        {
          const org = localStorage.getItem("orgYes");
          if(org == null)
          setYN({value:0,show:true})}
          else 
          {}
      },[active])

      return (<>

<Lock/>

<ModalYN modal={[modalYN,setYN]}/>
  <HomeLoading show={[progShow,setProgShow]}/>
  <ModalC modal={modal}/>
<Grid container spacing={-1} >
<Grid item sx={{display:{xs:"none",sm:"none",md:"block",lg:"block",xl:"block"},
padding:1,
borderRight:"solid 1px"+style.colors.high,
height:"100vh",backgroundImage:"url('"+sbg+"')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundBlendMode: 'color',backgroundColor: '#ffffff99'}} 
xs={0} sm={0} md={3} lg={2.5} xl={2.5}>
<div style={{display: 'flex',
flexDirection: 'column',
justifyContent: 'center',
paddingLeft: '30%',
margin: '-8px',}}>
   <br /> <br /> <br />
<img src={logo}  style={{width:"88px",width: '88px',position: 'relative',left: '8px'}}/>
<Typography  variant="h4"  noWrap  component="a"  href="" sx={style.logoText}>
    ኢጵላሣጵ
</Typography>
</div>

<SideMenues mn={[Mn,setMn]} states={[active,setactive]} org={[modalYN,setYN]} />

</Grid>
<Grid item xs={12} sm={12} md={9} lg={9} xl={9.5}>
<UserMainAppBar mn={[Mn,setMn]} states={[active,setactive]} org={[modalYN,setYN]} />
<Profile states={[active,setactive]}/>
<Org states={[active,setactive]}/>
<Producs states={[active,setactive]}/>
<Promromtion states={[active,setactive]}/>
<Storage states={[active,setactive]}/>
</Grid>

</Grid>



<Break_point/>


  </>)
}

export default Home