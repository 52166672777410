import React from 'react'
import { useState } from 'react';
import { AppBar,Box,Button,IconButton,Container,Toolbar, Typography, ButtonGroup, Breadcrumbs, Link, Drawer, FormControl, FormGroup, FormControlLabel, Switch, Select, MenuItem, InputLabel} from '@mui/material'
import logo from "../assets/circle.png"
import style from '../styles';
import define from '../strings';
import {Avatar} from '@mui/material';
import user from '../assets/user.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { CheckBox, Home, Logout, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import SideMenues from './SideMenues';
import LogoutM from './Logout';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';

const UserMainAppBar = (props) => {
  const [active,setactive] = props.states
  const [modalYN,setYN] = props.org
    const [lang,setlang] = useState(define.Am)
    const navItems = ['Home', 'About', 'Contact'];
    const [location,setloaction] = useState([{name:"ዋና ገፅ",url:"/"}]);
    const [Mn,setMn] = props.mn;
    const [open,setopen] = useState(false)
    const [openst,setoenst] = useState(false);
    const handClose = ()=> {

      setoenst(false)

    }

    const change = (e)=> {

      let item = localStorage.getItem('orgYes');
      if(item == null)
      {
        localStorage.setItem('orgYes','true');
        window.location.reload();
      }
      else {
        localStorage.removeItem('orgYes');
        localStorage.removeItem('org');
      
      }


      

    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const mopen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClosem = () => {
      setAnchorEl(null);
    };


  return (
    <>
 

    <LogoutM states={[open,setopen]}/>
    <AppBar sx={{boxShadow:"none",borderBottom:"solid 1px"+style.colors.high}} color='white' position="static" component="nav">
    <Container maxWidth="xl">
      <Toolbar color='white' disableGutters>
      
      <IconButton onClick={()=>setMn(true)} sx={{display:{xs:"block",sm:"block",md:"none",lg:"none",xl:"none"}}}>
        <MenuIcon/>
      </IconButton>
      
<>
{location.map((loc)=><>
  <Link underline="hover" key={loc.url} sx={{fontWeight:"900"}} color="inherit" href={""+loc.url}>
   {loc.name}
  </Link>
</>)}
</>





        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Box sx={{ float:"right", display: { xs: 'none', sm: 'none',md:"block",lg:"block",xl:"block" } }}>
          <ButtonGroup
  disableElevation
  variant="text"
  aria-label="Disabled elevation buttons"
><>
<IconButton color='primary'
 id="basic-button"
 aria-controls={open ? 'basic-menu' : undefined}
 aria-haspopup="true"
 aria-expanded={open ? 'true' : undefined}
 onClick={handleClick} variant='body1'
>
<NotificationsIcon/><Typography>ማሳወቅያ</Typography>
          </IconButton>
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={mopen}
        onClose={handleClosem}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{padding:15}}

      >
        <MenuItem         style={{padding: '18px',
        paddingBottom: '0'}} onClick={handleClosem}>
          <Typography variant='body1' style={{color:style.colors.high1}}>ምንም ማሳወቅያ ዮሎትም</Typography>
          <br /><br />
        </MenuItem>

      </Menu>

          </>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <IconButton onClick={e=>setoenst(true)} color='primary' sx={{ p: 0 }}>
          <Settings/><Typography variant='body1'>ማስተካከያ</Typography>
              </IconButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <IconButton onClick={e=>setopen(true)} color='primary' sx={{ p: 0 }}>
          <Logout/><Typography variant='body1'>መውጫ</Typography>
              </IconButton>
      
      
</ButtonGroup>
          </Box>
          <Box sx={{ float:"right", display: { xs: 'block', sm: 'block',md:"none",lg:"none",xl:"none" } }}>
          <ButtonGroup
  disableElevation
  variant="text"
  aria-label="Disabled elevation buttons"
>
<IconButton color='primary' 
 id="basic-button"
 aria-controls={open ? 'basic-menu' : undefined}
 aria-haspopup="true"
 aria-expanded={open ? 'true' : undefined}
 onClick={handleClick} variant='body1'
>
            <NotificationsIcon/>
          </IconButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
        <IconButton onClick={e=>setoenst(true)}  color='primary' sx={{ p: 0 }}>
          <Settings/>
              </IconButton>
              <IconButton onClick={e=>setopen(true)} color='primary' sx={{ p: 0 }}>
          <Logout/>
              </IconButton>

</ButtonGroup>
          </Box>      
          </Toolbar>
    </Container>
  </AppBar>
    <Drawer
    sx={{width:"54%"}}
      anchor="left"

      open={Mn}
      onClose={()=>setMn(false)}
    >

      <SideMenues mn={[Mn,setMn]} states={[active,setactive]} org={[modalYN,setYN]} />
    </Drawer>

    <Dialog
        open={openst}
       onClose={handClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {<IconButton  color='primary' sx={{ p: 0 }}>
          <Settings/><Typography variant='body1'>&nbsp;&nbsp;ማስተካከያ</Typography>
              </IconButton>}
        </DialogTitle>
        <DialogContent>
         <Typography variant='body1' color='secondary'> የአድራሻ ዐውድ ማስተካከያወች </Typography>
          <FormGroup>
            <FormControl>
              <InputLabel>ቋንቋ</InputLabel>
              <Select
              label="ቋንቋ"
              variant='standard'
              >
                <MenuItem value='1'>አማረኛ</MenuItem>
                <MenuItem value='2'>ኢንግሊዘኛ</MenuItem>
                <MenuItem value='3'>ኦሮሞኛ</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <hr />
  <FormControlLabel control={<Switch onChange={e=>change(e)}   defaultChecked />} label="የድርጅት ሜኑ" />
</FormGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handClose}>
            እሺ
          </Button>
        </DialogActions>
      </Dialog>




  </>)

}

export default UserMainAppBar
