const define = {
  Am: {
    logoText: "Eplusapp",
    welcometxt: "Well Come To Eplusapp Digital Address!",
    suggest1: "Login To Eplusapp!",
    suggest2: "Sign Up!",
    idlable: "Unique Id",
    password: "password",
    loginBtn: "login",
    createNewBtn: "Create New Address",
    faqbtn: "More",
    faqdesc:
      "If you are new to this page and want to know more about the address, click the button that says more",
    regBtn: "Submit",
    haveAcc: "Already have an account?Log in.",
    fname: "First Name",
    mname: "Middel Name",
    lname: "Last Name",
    sex: "Sex",
    email: "Email",
    phone: "PhoneNumber",
  },
  En: {},
};
export default define;
