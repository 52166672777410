import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Add, AdjustOutlined, Handshake, Person2, Storage } from '@mui/icons-material';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Skeleton, Typography } from '@mui/material';
export default function SideMenues(props) {
  const userInfo = useSelector((state)=>state.userInfo.user)
  const [open, setOpen] = React.useState(true);
  const [active,setactive] = props.states;
  const [show,setshow] = React.useState(true);
  const [Mn,setMn] = props.mn;
  const [popen,setpopen] = React.useState(false)
  const handleClick = () => {
    alert("")
    setOpen(!open);
  };

  React.useEffect(()=>
  {  const org = localStorage.getItem("org");
  if(org == null)
  {}
  else 
    setshow(false);
},[])

  const close = () =>{

    setMn(false)

  }


  

  return (
    <List
      sx={{ width: {xs:"44vh",sm:"44vh",md:'100%',lg:'100%',xl:'100%'}, maxWidth: 360, }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
              <ListItemButton sx={{bgcolor:"transparent",fontWeight:"900"}} component="div" id="nested-list-subheader">
           <Typography variant='body1'>
           {userInfo.firstName == undefined?<Skeleton animation="wave" />:userInfo.firstName+" "+userInfo.lastName}
           </Typography>
        </ListItemButton>


        <Link to="/main" onClick={e=>{setactive(1);}}>
      <ListItemButton sx={active ==1?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <Person2 style={active==1?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==1?{color:"white"}:{}} primary="የኔ መረጃ" />
      </ListItemButton>
      </Link>

      <Link to="/main" onClick={e=>{setactive(5);}}>
      <ListItemButton sx={active ==5?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <Storage/>
        </ListItemIcon>
        <ListItemText style={active==5?{color:"white"}:{}} primary="ማስቀመጫ" />
      </ListItemButton>
      </Link>


{show?     <>   <Link to="/main/org/0"  onClick={e=>{setOpen(true);setactive(2); close()}}>
      <ListItemButton sx={active ==2?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <AssuredWorkloadIcon style={active==2?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==2?{color:"white"}:{}} primary="የድርጅት ማስተዳደርያ" />
      </ListItemButton>
      </Link>
      
      
      <Collapse in={open} timeout="auto" unmountOnExit>
<List component="div" disablePadding>
<Link to="/main/org/0"  onClick={e=>{setactive(2); close();}}>
  <ListItemButton  sx={{ pl: 4 }}>
    <ListItemIcon>
      <Handshake/>
    </ListItemIcon>
    <ListItemText primary="የኔ ድርጅት" />
  </ListItemButton>
  </Link>
  <Link to="/main/org/1"  onClick={e=>{setactive(2); close()}}>
  <ListItemButton sx={{ pl: 4 }}>
    <ListItemIcon>
      <Add />
    </ListItemIcon>
    <ListItemText primary="አዲስ ድርጅት" />
  </ListItemButton>
  </Link>
  
</List>
</Collapse>
</>
:""}


<Link to="/main"  onClick={e=>{setactive(3); close();setpopen(true)}}>
      <ListItemButton sx={active ==3?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <LocalMallIcon style={active==3?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==3?{color:"white"}:{}} primary="የምርት ማስተዳደርያ" />
      </ListItemButton>
      </Link>

      <Link to="/main"  onClick={e=>{setactive(4); close();setpopen(true)}}>
      <ListItemButton sx={active ==4?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <AdjustOutlined style={active==4?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==4?{color:"white"}:{}} primary="የማስታወቅያ ማስተዳደርያ" />
      </ListItemButton>
      </Link>
      

    </List>
  );
}