import React, { useRef } from 'react'
import { UserCard,TaggedContentCard } from 'react-ui-cards'
import style from './../../styles';
import { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Skeleton, Typography ,Button, Avatar, Grid, Card, CardHeader, CardContent, Box, List, ListItem, ListItemIcon, ListItemText, IconButton, ListItemAvatar, FormControlLabel, Input, TextField, Switch, Tabs, Tab, Container, ButtonGroup, FormControl, ListItemButton, Select, MenuItem} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDetail } from '../../data/OrgDetail';
import Loading from '../../components/Loading';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import logo from '../../assets/circle.png';
import ProgressBar from '../../components/ProgressBar';
import styled from '@emotion/styled';
import { Add, ArrowRight, Camera, Cancel, Delete, Download, FolderSpecial, Forest, Group, HomeSharp, LineAxis, Link, School, TaskAlt, UpdateOutlined, UpdateRounded, UpdateSharp } from '@mui/icons-material';
import api from '../../data/api';
import SnackBar from '../../components/SnackBar';
import { UserInfo } from '../UserInfo';
const baseUrl = api.address;
const img = api.imgAddress;

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const View = () => {

  const {state} = useLocation();
  const id = state?.id;
  const link = useNavigate();
  const run = useDispatch();
  const lol = useSelector((state)=>state.orgDetail.detail)
  const [detail,setDetail] = useState(lol)
  const [load,setload] = useState(false)
  const teftef = null
  const user = useSelector((state)=>state.logedUser)
  const [ask,setask] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [edit,setEdit] = useState(false)
  const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  const [texts,setxts] = useState({title:"ስለ ድርጅትወ ያላለቀ መረጃ አለ?",desc:" ስለ ድርጅትዎ መሙላት ያልብዎ ቅፅ አለ ወደ ቅፁ እሚለውን በመጫን ያልጨረሱትን ዎይም ያልሞሉትን መረጃ ሞልተው ማጠናቀቅ ይችላሉ",btn1:"ይቆይ",btn2:"ወደ ቅፁ"})
  const [postion,setPostion] = useState({orgId:null,title:'',description:''});
  const [tab,setTab] = useState(0)
  const [posList,setPosList] = useState([])
  const [updateBtn,setUpdateBtn] = useState(false)
  const [users,setUsers] = useState([])
  const [crUser,setCrUser] = useState(null)
  const logoref = useRef();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  useEffect(()=>{



    console.log('Detail:--',lol)
    setDetail(lol)



  },[lol])

  useEffect( ()=>{

    (async ()=>{

      await axios.get(`${api.address}/addressapi/organizations/positions/all`,{
        headers: {
          'Authorization': `Bearer ${user.user}`
        }     
      })
      .then(resp=>{
        console.log("Postion List",resp.data)
        setPosList(resp.data)
      })
      .catch((err)=>{
        console.log("Postion List",err)
      })

    })()
    

    run(fetchDetail({id:id,token:user.user})) 
    
  },[])

 
  const finish = ()=>{
   
    
    console.log(detail);

    setOpen(!open);
      if(teftef == null && ask)
      {
        console.log("No teftef")
        link('../2',{state:{id:id}});
      }
      else if(detail.Hasab == null && ask)
      {
        console.log("No hasab")
       link('../3',{state:{id:id}});        
      }
      else if(detail.Legality == null && ask)
      {
        console.log("No legality")
       link('../4',{state:{id:id}});        
      }
      else if(detail.Projectoffice == null && ask)
      {
        console.log("No project office")
        link('../5',{state:{id:id}});        
      }
      else if(detail.Promotion == null && ask)
      {
        console.log("No promotion")
        link('../7',{state:{id:id}});        
      }
      else if(detail.Plan == null && ask)
      {
        console.log("No plan")
        link('../8',{state:{id:id}});        
      }
      else if(detail.Training == null && ask)
      {
        console.log("No training")
        link('../8',{state:{id:id,plan:false,train:true}});        
      }
      else if(detail.Jobskill == null && ask)
      {
        console.log("No Job Skill")
        link('../8',{state:{id:id,plan:false,train:false,job:true}});        
      }
      else if(teftef != null && detail.Hasab != null && detail.Legality != null && detail.Projectoffice != null && detail.Promotion != null && detail.Plan != null && detail.Plan !=  null)
      {
        setxts({title:"ሁሉንም መረጃ አሟልተው ጨርሰዋል",desc:"",btn1:"እሺ",btn2:""});
       
      } 
      else 
      setask(!ask)

  } 


  const Update = e => {


    setDetail({...detail,[e.target.name]:e.target.value})
  }



  const SendToBack = e => {

    axios.put(`${api.address}/addressapi/organizations/${detail.id}`,{[e.target.name]:e.target.value},{
      headers: {
        'Authorization': `Bearer ${user.user}`
      }   
    })
    .then(resp=>{

      setsnack({Type:"info",msg:"ያስገቡት መረጃ በትክክል ተቀይሮል!",show:true})



    })
    

  }


  const submitPos = async () => {

    if(postion.title == '')
    setsnack({Type:"error",msg:"የስራ ሃላፊነቱን ስም ያስገብ!",show:true})
    else if(postion.description == '')
    setsnack({Type:"error",msg:"የስራ ሃላፊነቱን ማብራርያ ያስገብ!",show:true})
    else 
    {
      setload(true)
      await axios.post(`${api.address}/addressapi/organizations/positions/create`,postion,{
        headers: {
          'Authorization': `Bearer ${user.user}`
        }     
      })
      .then((resp)=>{
        setsnack({Type:'info',msg:'መረጃው ገብትዋል',show:true})
        setPosList([...posList,resp.data]);
        setload(false)
        setPostion({orgId:null,title:'',description:''})
      })
      .catch((err)=>{
        console.log(err)
        setsnack({Type:'error',msg:err.response.data.message,show:true})
        setload(false)
      })

    }





  }

useEffect(()=>{

  console.log(detail)
  if(detail?.isloading)
  setload(true)
  else 
  setload(false)

},[detail,ask])



const updatePos = async ()=>{

  let dataToSend = postion;
  let id = postion.id;
  delete dataToSend['id'];
  delete dataToSend['createdAt']
  delete dataToSend['updatedAt']
  delete dataToSend['orgId']
setload(true)
await axios.put(`${api.address}/addressapi/organizations/positions/${id}`,dataToSend,{
  headers: {
    'Authorization': `Bearer ${user.user}`
  }     
})
.then(async resp=>{

  setsnack({Type:'info',msg:"ያስገቡት መርጃ በትክክል ተስተካክልዋል!",show:true})
  
    await axios.get(`${api.address}/addressapi/organizations/positions/all`,{
      headers: {
        'Authorization': `Bearer ${user.user}`
      }     
    })
    .then(resp=>{
      setPosList(resp.data)
    })



setload(false)
console.log(resp)

  
})
.catch(err=>{
  setsnack({Type:'error',msg:err.response?.data?.message,show:true})
setload(false)
setUpdateBtn(false)
setPostion({orgId:null,title:'',description:''})


console.log(err)



})



}

  return (
    <>
          <SnackBar snack={snack}/>
  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {texts.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {texts.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{texts.btn1}</Button>
          <Button onClick={e=>{ setask(true);finish()}} autoFocus>
            {texts.btn2}
          </Button>
        </DialogActions>
      </Dialog>
 <Loading load={load} />

 <br /><br />

<Grid container>
<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
  <Avatar alt='' sx={{height: '130px',width: '130px',}} src={`${img}${detail?.logo}`}/>
  
  {edit?<div style={{padding:11}}>
    <Button onClick={e=>logoref.current.click()} startIcon={<Camera/>} size='large' variant='outlined' >ሎጎ መቀየር</Button>
    <input type="file" onChange={ async e=>{

    let file = e.target.files[0]
    let fd = new FormData();
    fd.append("logo",file)

    setload(true)
   await axios.post(`${api.address}/addressapi/files/uploadfile`,fd).then(async (resp)=>{
                
      console.log(resp.data.logo)

      await    axios.put(`${api.address}/addressapi/organizations/${detail.id}`,{logo:resp.data.logo},{
        headers: {
          'Authorization': `Bearer ${user.user}`
        }   
      })
      .then(resp1=>{
      
        setDetail({...detail,logo:resp.data.logo})
        setsnack({Type:"info",msg:"ያስገቡት መረጃ በትክክል ተቀይሮል!",show:true})
      
      })
      .catch((err)=>{
        console.log(err)
      })






  setload(false)
  
}).catch((err)=>{
  console.log(err)
  if(err)
  setsnack({Type:"warning",msg:"የኔት ዎርክ ችግር አለ፣ እባክዎ እንደገና ይሞክሩ!",show:true})
  setload(false)

})









    }} ref={logoref} style={{display:'none'}}/>

  </div>:""}

  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
  <Typography variant='h4' sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}} color=''>{detail?.name}</Typography>
  <Typography variant='h6' sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}} color='secondary'> <span><strong> <i>እንቁ ቁጥር</i> [ &nbsp; {detail?.digitalID} &nbsp; ]</strong></span></Typography>
  <Typography variant='h6' sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}} color='primary'><a style={{color: '#0099ff'}} href={`email:${detail?.email}`}> {detail?.email}</a></Typography>
  
  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
  <Typography variant='body1' sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}} color=''>ብራንድ፡ {detail?.brand}</Typography>
  <Typography variant='body1' sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}} color=''>ከ {detail?.establishment} ጀምሮ</Typography>
    <Button variant='contained' startIcon={<Download/>} >መረጃውን አወርድ</Button>
  </Grid>
</Grid>

<Tabs
        value={tab}
        aria-label="Tabs where each tab needs to be selected manually"
        sx={{display:{
          xs:"none",
          sm:"none",
          md:"block",
          lg:"block",
          xl:"block",
          
        }}}
        color='#fe9900'
        style={{marginBlock: '23px',
        padding: '5px',
        borderBottom: 'inset 2px',
        borderColor: '#fe9900'}}
        >
        <Tab  color='#fe9900'  onClick={()=>setTab(0)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>የድርጅቱ መረጃ</Typography>} />
        <Tab onClick={()=>setTab(1)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>የድርጅቱ ሰራተኞች</Typography>}  />
        <Tab onClick={()=>setTab(2)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>የድርጅቱ ሃላፊነትዎች</Typography>}/>
      </Tabs>


<Container style={{transition: 'ease-in-out 0.5s'}} sx={{display:tab==0?'':'none'}}>
<Grid container>
  <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
  <Box>
</Box>
  </Grid>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <>
<br/>
<FormControlLabel control={<Switch  onChange={e=>setEdit(!edit)} value={edit}  />}  label="ማየት/ ማስተካከል" />
<FormControlLabel control={<Switch  onChange={ async e=>{setDetail({...detail,forPromotion:e.target.checked})}} onBlur={ async e =>{

await    axios.put(`${api.address}/addressapi/organizations/${detail.id}`,{forPromotion:detail.forPromotion},{
  headers: {
    'Authorization': `Bearer ${user.user}`
  }   
})
.then(resp=>{

  setsnack({Type:"info",msg:"ያስገቡት መረጃ በትክክል ተቀይሮል!",show:true})

})

}} checked={detail?.forPromotion}  />}  label="ድርጅቱ ለማስታዎቅያ ውልዋል" />

    <CardHeader sx={{borderBottom: 'solid 2px #fe9900'}} title={<Typography variant='h5' color='secondary'>የድርጅቱ መረጃዎች!</Typography>}/>
      <CardContent>
      <Grid container>


<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ሃገር</Typography>
  
  {edit?<TextField
  style={{margin:5}}
  value={detail?.country}
  name='country'
  onBlur={SendToBack}
  onChange={Update}

/>:<Typography  variant='body1'>{detail?.country}</Typography>}


  </Grid>



  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5' >ክልል</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.region}
  onBlur={SendToBack}
  name='region'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.region}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography   variant='h5'>ዞን</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.zone}
  onBlur={SendToBack}
  name='zone'
  
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.zone}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ከተማ</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.city}
  name='city'
  onBlur={SendToBack}
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.city}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ከተማ/ወረዳ</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.woreda}
  name='woreda'
  onBlur={SendToBack}
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.woreda}</Typography>}
  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ክፍለ ከተማ</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.subCity}
  onBlur={SendToBack}
  name='subCity'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.subCity}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ቀበሌ</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.kebele}
  onBlur={SendToBack}
  name='kebele'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.kebele}</Typography>}
  </Grid>


</Grid>
     
    </CardContent>
    
      <CardContent>
      <Grid container>


<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>ካፒታል</Typography>
  {edit?<TextField
  onBlur={SendToBack}
  style={{margin:5}}
  value={detail?.capital}
  name='capital'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.capital}</Typography>}

  </Grid>



  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>የተቀጣሪዎች ብዛት</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.employees}
  onBlur={SendToBack}
  name='employees'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.employees}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>የአክሲዮን መስራቾች ብዛት</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.stockFounder}
  name='stockFounder'
  onBlur={SendToBack}
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.stockFounder}</Typography>}

  </Grid>


  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>አሁን ያለው የአክሲዮን ብዛት</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.currentStock}
  onBlur={SendToBack}
  name='currentStock'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.currentStock}</Typography>}
  </Grid>

  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>የግብር ቁጥር</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.tinNumber}
  onBlur={SendToBack}
  name='tinNumber'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.tinNumber}</Typography>}

  </Grid>

 
  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>የስራ መስክ</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.businessSector}
  onBlur={SendToBack}
  name='businessSector'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.businessSector}</Typography>}

  </Grid>


 
  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
  <Typography  variant='h5'>የተቀጣሪዎች ብዛት</Typography>
  {edit?<TextField
  style={{margin:5}}
  value={detail?.employees}
  onBlur={SendToBack}
  name='employees'
  onChange={Update}
  />:<Typography  variant='body1'>{detail?.employees}</Typography>}
  </Grid>

 

</Grid>

     
    </CardContent>


    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>


    </>
  </Grid>
  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>

  </Grid>
</Grid>
</Container>

<Container  sx={{display:tab==1?'':'none'}}>

<Grid container sx={{padding:2}}>
<Grid xs={12} sm={12} lg={3} xl={3} md={3} item>
<TextField 
label="ፈልግ"
onChange={async e=>{
let query = e.target.value;
if(query != '')
await axios.get(`${api.address}/addressapi/users/search?key=${query}`)
.then((resp)=>{
  setUsers(resp.data)
console.log(resp.data)
})
.catch(err=>{
  if(err)
  {
    console.log(err.response)
    setsnack({Type:'error',msg:err.response?.data.message,show:true})
  }
})

}}
/>
<List>
<hr />
{
users.map((user)=>(
<ListItem style={{cursor:'pointer'}} onClick={()=>setCrUser(user)}>
    <ListItemAvatar>
      <Avatar src={`${api.imgAddress}/${user.avatar}`}/>
    </ListItemAvatar>
    <ListItemText primary={user.firstName+' '+user.middleName} secondary={user.position}/>
  </ListItem>
  ))
}

<hr />
<Typography variant='h6' style={{textAlign:'center'}}>በድርጅቱ ስር ያሉ ሰራተኞች</Typography>
<hr />
{
  detail?.Users != null? detail?.Users.map((emp=>(
<ListItem style={{cursor:'pointer'}} onClick={()=>setCrUser(emp)}>
    <ListItemAvatar>
      <Avatar src={`${api.imgAddress}/${emp.avatar}`}/>
    </ListItemAvatar>
    <ListItemText primary={emp.firstName+' '+emp.middleName} secondary={
      posList.map((pos)=>{
        if(pos.id == emp.positionId)
        return pos.title
      })
    }/>
  </ListItem>
  ))):''
  }

</List>
</Grid>
<Grid xs={12} sm={12} md={1} lg={1} xl={1} item>
</Grid>
<Grid xs={12} sm={12} md={7} lg={7} xl={7} item>
{ crUser != null ?
 <Grid container>
<Grid item sm={2}>
  <Avatar src={`${api.imgAddress}/${crUser.avatar}`} sx={{width:75,height:75}}/>
  </Grid>
  
  <Grid item sm={10}>
  <Typography variant='h4'>{crUser.firstName+' '+crUser.middleName}</Typography>
  <Typography variant='h6'> <School/> {crUser.education}</Typography>
  <Typography variant='h6'>{crUser.orgId == null ? 'ለጊዜው የሚሰሩበት ድርጅት የለም': <>
{
 posList.map((pos)=>{
  if(pos.id == crUser.positionId)
  return pos.title
})
}
  </> }</Typography>
  <hr />
<ButtonGroup>
  
<Select color='primary' onChange={ async (e)=>{
  let positionId = e.target.value;
  let userId = crUser.id;
  console.log(crUser)
  if(positionId != 'Nan')
{
  setload(true)

    await axios.post(`${api.address}/addressapi/organizations/positions/assign-position`,{positionId:positionId,userId:userId},{
    headers: {
      'Authorization': `Bearer ${user.user}`
    }   

  })
  .then(async (resp)=>{
    setCrUser(resp.data)
    setsnack({Type:'info',msg:'ተሳክትዋል!',show:true})
    setload(false)
console.log(resp.data)

await axios.get(`${api.address}/addressapi/organizations/${detail.id}`)
.then(resp=>{
  console.log(resp.data)
})


  })
  .catch(err=>{
    console.log(err)
    setload(false)
  })
}


  
}} value='Nan'>
<MenuItem  value='Nan'>ይሰይሙ/ይቀይሩ</MenuItem>
{
  
  posList.map((pos)=>(
    <MenuItem value={pos.id}>{pos.title}</MenuItem>
  ))
}

</Select>
<Button>ሃላፊነት ፍጠር</Button>
</ButtonGroup>
  <hr />
  <Grid container>
  <Grid item sm={4}>
    <Typography variant='h6'>ሙያ</Typography>
    <Typography variant='body1'>{crUser.profession}</Typography>
    </Grid>

    <Grid item sm={4}>
    <Typography variant='h6'>ስልክ ቁጥር</Typography>
    <Typography variant='body1'>{crUser.phoneNumber}</Typography>
    </Grid>

    <Grid item sm={4}>
    <Typography variant='h6'>የልደት ቀን</Typography>
    <Typography variant='body1'>12-02-2015</Typography>
    </Grid>
  </Grid>
  <div><UserInfo posList={posList} org={detail}  userInfo={crUser}/></div> 

  <Button variant='contained' startIcon={<Link/>} onClick={()=>{ 

link('/userdetail',{state:{item:crUser}})

  }} sx={{margin:5,marginLeft:0}}>ተጨማሪ መረጃ</Button>
  
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  
  </Grid>
  
</Grid>:
<Typography variant='h4' style={{margin:20}}>{detail?.name} የሰራተኞች ሃላፊነት ማስተዳደርያ</Typography>
}




</Grid>
</Grid>


</Container>

<Container sx={{display:tab==2?'':'none'}}>
  <Container>
    <Grid container>
<Grid item sm={8}>
<List>

{
  
  posList.map((pos)=>(
  <ListItem>
    <ListItemText primary={<Typography variant='h6'>{pos.title}</Typography>} secondary={pos.description}/>
    <ListItemButton color='primary' onClick={()=>{setPostion(pos); setUpdateBtn(true)}}>
      <IconButton>
        <UpdateSharp/>
      </IconButton>
      ማስተካከል
    </ListItemButton>
  </ListItem>
  ))
}

</List>
</Grid>
<Grid item sm={4}>
    <Typography>የስራ ሃላፊነቱ ስም</Typography>
    <TextField value={postion.title} onChange={e=>setPostion({...postion,title:e.target.value})} sx={{width:'100%'}} placeholder='ሃላፊነት' />
    <br /><br />
    <Typography>የስራ ሃላፊነቱ ማብራርያ</Typography>
    <TextField sx={{width:'100%'}} value={postion.description} onChange={e=>setPostion({...postion,orgId:detail.id,description:e.target.value})} placeholder='ማብራርያ' multiline rows={5} />
    <br /><br />
{updateBtn?<><Button onClick={updatePos} variant='contained' color='secondary' style={{color:'white'}} startIcon={<UpdateOutlined/>}>አስተካክል</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={()=>{setUpdateBtn(false); setPostion({orgId:null,title:'',description:''})}} startIcon={<Cancel/>}>አቋርጥ</Button></>:
<Button onClick={submitPos} variant='contained' startIcon={<Add/>}>አስገባ</Button>  
    }
    
</Grid>

    </Grid>
  </Container>
</Container>







{/* 
<Skeleton/>
<Skeleton variant="rectangular"  height={90} />
<br />
<Skeleton variant="rounded"  height={190} />
<Skeleton/>
<Skeleton variant="rectangular"  height={90} />
<Skeleton/>
<Skeleton variant="rectangular"  height={90} />
<br />
<Skeleton variant="rounded"  height={190} /> */}

    </>

    )
}

export default View